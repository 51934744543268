<template>
	<div class="container">
		<div class="user-nav">
			<el-row type="flex" justify="space-between">
				<el-col :span="8">
					<el-select v-model="termName" placeholder="当前学期" style="width: 195px; margin-top: 10px;margin-left: 10px;" @change="handleChange">
						<el-option v-for="(item, index) in termlist" :key="index" :label="item.termName"
							:value="item.termName"></el-option>
					</el-select>
				</el-col>
				<el-col :span="16" style="text-align: right">
					<div class="userinfo">
						<p>{{ paramsData.userName }}</p>
						<span>学籍号：{{ paramsData.studentCode }}</span>
						<span>{{ paramsData.className }}</span>
					</div>
				</el-col>
			</el-row>
		</div>

		<div class="main">
			<div class="table-box">
				<h4>艺术素养</h4>
				<!-- <table border="1" cellspacing="0" rules="all">
					<tr>
						<th>项目</th>
						<th>写实记录</th>
						<th>艺术课考勤</th>
						<th class="long-td">艺术课堂学习参与度</th>
						<th class="long-td">艺术课程学习任务完成度</th>
						<th>艺术测评</th>
						<th>艺术技能测试</th>
						<th>总计</th>
					</tr>
					<tr>
						<td>数据条数</td>
						<td>{{ type200Num }}</td>
						<td>{{ type3341Num }}</td>
						<td>{{ type3342Num }}</td>
						<td>{{ type3343Num }}</td>
						<td>{{ type3344Num }}</td>
						<td>{{ type3351Num }}</td>
						<td>{{ totalNum }}</td>
					</tr>
					<tr>
						<td>综素成绩（分）</td>
						<td>{{ type200Score }}</td>
						<td>{{ type3341Score }}</td>
						<td>{{ type3342Score }}</td>
						<td>{{ type3343Score }}</td>
						<td>{{ type3344Score }}</td>
						<td>{{ type3351Score }}</td>
						<td>{{ totalScore }}</td>
					</tr>
				</table> -->
				<table border="1" cellspacing="0" rules="all" id="total-table1">
					<tr>
						<th>分项</th>
						<th>艺术课出勤率</th>
						<th>艺术课参与度</th>
						<th>艺术课任务完成度</th>
						<th>艺术测评</th>
						<th>艺术赏析</th>
						<th>民间艺术</th>
					</tr>
					<tr>
						<td>数据条数</td>
						<td>{{ typeNum3341 }}</td>
						<td>{{ typeNum3342 }}</td>
						<td>{{ typeNum3343 }}</td>
						<td>{{ typeNum3344 }}</td>
						<td>{{ typeNum3345 }}</td>
						<td>{{ typeNum3346 }}</td>
					</tr>
					<tr>
						<td>综素成绩（分）</td>
						<td>{{ typeScore3341 }}</td>
						<td>{{ typeScore3342 }}</td>
						<td>{{ typeScore3343 }}</td>
						<td>{{ typeScore3344 }}</td>
						<td>{{ typeScore3345 }}</td>
						<td>{{ typeScore3346 }}</td>
					</tr>
				</table>
				<table border="1" cellspacing="0" rules="all" id="total-table2">
					<tr>
						<th>分项</th>
						<th>艺术技能成绩</th>
						<th>艺术兴趣</th>
						<th>校外艺术活动</th>
						<th>艺术展演</th>
						<th>艺术特长</th>
						<th>总计</th>
					</tr>
					<tr>
						<td>数据条数</td>
						<td>{{ typeNum3351 }}</td>
						<td>{{ typeNum3352 }}</td>
						<td>{{ typeNum3353 }}</td>
						<td>{{ typeNum3354 }}</td>
						<td>{{ typeNum3361 }}</td>
						<td>{{ typeSumCount }}</td>
					</tr>
					<tr>
						<td>综素成绩（分）</td>
						<td>{{ typeScore3351 }}</td>
						<td>{{ typeScore3352 }}</td>
						<td>{{ typeScore3353 }}</td>
						<td>{{ typeScore3354 }}</td>
						<td>{{ typeScore3361 }}</td>
						<td>{{ typeSumScore }}</td>
					</tr>
				</table>
			</div>
			<div class="more">
				<h4>相关记录</h4>
				<el-row type="flex" justify="space-between" class="more-tit more-tittop">
					<el-col :span="12">
						<h5>艺术课出勤率</h5>
					</el-col>
					<el-col :span="12" style="text-align: right"> </el-col>
				</el-row>
				<div class="table-box">
					<table border="1" cellspacing="0" rules="all">
						<tr>
							<th>评价项目</th>
							<th>学期</th>
							<th>评价方式</th>
							<th class="long-td">艺术课出勤率</th>
							<th class="long-td">综素得分</th>
						</tr>
						<tr v-if="artStudent">
							<td>艺术课出勤率</td>
							<td>{{ artStudent.termName }}</td>
							<td>客观记录</td>
							<td>{{ artStudent.artRatio }}</td>
							<td>{{ artStudent.indexUserScore }}</td>
						</tr>
						<tr v-else>
							<td class="empty-cell" colspan="5">暂无数据</td>
						</tr>
					</table>
				</div>
				<el-row type="flex" justify="space-between" class="more-tit more-tittop">
					<el-col :span="12">
						<h5>艺术课堂学习参与度</h5>
					</el-col>
					<el-col :span="12" style="text-align: right"> </el-col>
				</el-row>
				<div class="table-box">
					<table border="1" cellspacing="0" rules="all">
						<tr>
							<th>评价项目</th>
							<th>学期</th>
							<th>评价方式</th>
							<th class="long-td">艺术课程学习参与度</th>
							<th class="long-td">综素得分</th>
						</tr>
						<tr v-if="learnProgressType1">
							<td>艺术课程学习参与度</td>
							<td>{{ learnProgressType1.termName }}</td>
							<td>客观记录</td>
							<td>{{ learnProgressType1.avgLearnProgress }}</td>
							<td>{{ type3342Score }}</td>
						</tr>
						<tr v-else>
							<td class="empty-cell" colspan="5">暂无数据</td>
						</tr>
					</table>
				</div>
				<el-row type="flex" justify="space-between" class="more-tit more-tittop">
					<el-col :span="12">
						<h5>艺术课堂学习任务完成度</h5>
					</el-col>
					<el-col :span="12" style="text-align: right"> </el-col>
				</el-row>
				<div class="table-box">
					<table border="1" cellspacing="0" rules="all">
						<tr>
							<th>评价项目</th>
							<th>学期</th>
							<th>评价方式</th>
							<th class="long-td">艺术课程学习参与度</th>
							<th class="long-td">综素得分</th>
						</tr>
						<tr v-if="learnProgressType2">
							<td>艺术课程学习参与度</td>
							<td>{{ learnProgressType2.termName }}</td>
							<td>客观记录</td>
							<td>{{ learnProgressType2.avgLearnProgress }}</td>
							<td>{{ type3343Score }}</td>
						</tr>
						<tr v-else>
							<td class="empty-cell" colspan="5">暂无数据</td>
						</tr>
					</table>
				</div>
				<el-row type="flex" justify="space-between" class="more-tit more-tittop">
					<el-col :span="12">
						<h5>艺术知识测评</h5>
					</el-col>
					<el-col :span="12" style="text-align: right"> </el-col>
				</el-row>
				<el-table :data="studentArtEva" style="width: 100%" border :header-cell-style="tableHeaderColor"
					:cell-style="cellStyleFun">
					<el-table-column type="index" label="序号" width="50">
					</el-table-column>
					<el-table-column prop="name" label="测评名称"> </el-table-column>
					<el-table-column prop="evaluationTypeName" label="测评类别">
						<template slot-scope="scope">艺术知识测评</template>
					</el-table-column>
					<el-table-column prop="term" label="学期" width="180">
					</el-table-column>
					<el-table-column prop="status" label="状态" width="120">
						<template slot-scope="scope">
							{{ scope.row.indexScore == "暂无" ? "未测评" : "已测评" }}
						</template>
					</el-table-column>
					<el-table-column prop="indexScore" label="综素得分" width="120">
					</el-table-column>
				</el-table>
				<el-row type="flex" justify="space-between" class="more-tit more-tittop">
					<el-col :span="12">
						<h5>学生艺术技能成绩</h5>
					</el-col>
					<el-col :span="12" style="text-align: right"> </el-col>
				</el-row>
				<div class="table-box">
					<table border="1" cellspacing="0" rules="all">
						<tr>
							<th class="long-td">学期</th>
							<th>项目1</th>
							<th>项目1成绩</th>
							<th>项目2</th>
							<th>项目2成绩</th>
							<th>平均成绩</th>
							<th>综素得分</th>
						</tr>
						<tr v-if="artSkillTest">
							<td>{{ artSkillTest.termName }}</td>
							<td>{{ artSkillTest.nameOne }}</td>
							<td>{{ artSkillTest.nameOneScore }}</td>
							<td>{{ artSkillTest.nameTwo }}</td>
							<td>{{ artSkillTest.nameTwoScore }}</td>
							<td>{{ artSkillTest.avgScore }}</td>
							<td>{{ artSkillTest.indexScore }}</td>
						</tr>
						<tr v-else>
							<td class="empty-cell" colspan="7">暂无数据</td>
						</tr>
					</table>
				</div>
				<el-row type="flex" justify="space-between" class="more-tit more-tittop">
					<el-col :span="12">
						<h5>艺术展演/比赛</h5>
					</el-col>
					<el-col :span="12" style="text-align: right"> </el-col>
				</el-row>
				<el-table :data="matchByArt" style="width: 100%" border :header-cell-style="tableHeaderColor"
					:cell-style="cellStyleFun">
					<el-table-column type="index" label="序号" width="50">
					</el-table-column>
					<el-table-column prop="matchName" label="展演/赛事名称"> </el-table-column>
					<el-table-column prop="indexRuleLevel" label="展演/赛事级别">
					</el-table-column>
					<el-table-column prop="matchTime" label="展演/比赛时间"> </el-table-column>
					<el-table-column label="操作" width="120">
						<template slot-scope="scope">
							<el-button @click="handleDetailRace(scope.row)" type="text" size="small">查看</el-button>
							<el-button @click="handleRemoveRace(scope.row)" type="text" size="small">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="back">
				<el-button type="text" @click="backIndex">返回</el-button>
			</div>
		</div>

		<!-- 查看详情 -->
		<el-dialog :title="title" :visible.sync="open" width="600px" center>
			<el-form ref="form" :model="form" style="
          border-top: 1px solid #e1e3e9;
          border-bottom: 1px solid #e1e3e9;
          padding-top: 5px;
        ">
				<el-form-item style="border-bottom: 1px dashed #e1e3e9">
					<span class="sname">{{ form.userName }}</span>
					<span class="snumb">学籍号：{{ form.studentCode }}</span>
				</el-form-item>
				<el-form-item label="展演/比赛名称：">
					<span class="scontent">{{ form.matchName }}</span>
				</el-form-item>
				<el-form-item label="展演/比赛级别：">
					<span class="scontent">{{ form.indexRuleLevel }}</span>
				</el-form-item>
				<el-form-item label="获奖等级：" prop="resultLevel">
					<span class="scontent">{{ form.resultLevel }}</span>
				</el-form-item>
				<el-form-item label="展演/比赛时间：">
					<span class="scontent">{{ form.matchTime }}</span>
				</el-form-item>
				<el-form-item label="举办单位：" style="border-bottom: 1px dashed #e1e3e9">
					<span class="scontent">{{ form.awardedUnit }}</span>
				</el-form-item>
				<el-form-item label="所在学期：">
					<span class="scontent">{{ form.termName }}</span>
				</el-form-item>
				<el-form-item label="佐证材料：" v-if="form._showImgList">
					<el-image v-for="(item, index) in form._imgFileList" style="width: 100px; height: 100px;"
						:preview-src-list="form._imgFileList" :key="index" :src="item"></el-image>
				</el-form-item>
				<el-form-item label="佐证材料：" v-if="form._showOtherFileList">
					<a v-for="(item, idx) in form._otherFileList" :key="item" target="_blank" :download="'附件' + idx"
						:href="item">附件{{ idx + 1 }} </a>
				</el-form-item>
				<el-form-item label="备注说明：">
					<span class="scontent">{{ form.bz }}</span>
				</el-form-item>
				<el-form-item v-if="form.auditStatus == 2" label="驳回者：">
					<span class="scontent">{{ form.updateBy }}</span>
				</el-form-item>
				<el-form-item v-if="form.auditStatus == 2" label="驳回原因：">
					<span class="scontent">{{ form.causeDes }}</span>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="open = false">关 闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getTerm
	} from "@/api/public/search";
	import {
		getArtRaceDetail,
		removeArtRace,
		artInfoDetail
	} from "@/api/teacher/quality/artistic";
	export default {
		data() {
			return {
				imgSrc: this.imageSrc(),
				termName: "",
				title: "",
				open: false,
				testList: [],
				form: {},
				termlist: [],
				paramsData: {},
				/* type200Num: 0,
				type200Score: 0,
				type3341Num: 0,
				type3341Score: 0,
				type3342Num: 0,
				type3342Score: 0,
				type3343Num: 0,
				type3343Score: 0,
				type3344Num: 0,
				type3344Score: 0,
				type3351Num: 0,
				type3351Score: 0,
				totalNum: 0,
				totalScore: 0, */
				artStudent: {},
				learnProgressType1: {},
				learnProgressType2: {},
				studentArtEva: [],
				artSkillTest: {},
				matchByArt: [],
				
				typeNum3341: 0,
				typeScore3341: 0,
				typeNum3342: 0,
				typeScore3342: 0,
				typeNum3343: 0,
				typeScore3343: 0,
				typeNum3344: 0,
				typeScore3344: 0,
				typeNum3345: 0,
				typeScore3345: 0,
				typeNum3346: 0,
				typeScore3346: 0,
				typeNum3351: 0,
				typeScore3351: 0,
				typeNum3352: 0,
				typeScore3352: 0,
				typeNum3353: 0,
				typeScore3353: 0,
				typeNum3354: 0,
				typeScore3354: 0,
				typeNum3361: 0,
				typeScore3361: 0,
				typeSumCount: 0,
				typeSumScore: 0
			};
		},
		created() {
			this.paramsData =
				this.$route.params.userId != undefined ?
				this.$route.params :
				JSON.parse(sessionStorage["params"]);
			this.termName = this.paramsData.termName;
			this.getFirst();
			this.getAll()
			
		},
		methods: {
			// 获取所有信息
			getAll() {
				artInfoDetail({ 
					userId: this.paramsData.userId,
					termName: this.termName
				}).then(res => {
					let d = res.data
					// 统统放到data里
					for(let i in d) {
						this[i] = d[i]
					}
				})
			},
			handleChange(val) {
				this.termName = val;
				this.getAll();
			},
			getFirst() {
				this.getTermList();
			},
			backIndex() {
				this.$router.go(-1);
			},
			// 处理附件
			processAth(key) {
				let temp = key.split(",")
				let imgFileList = []
				let otherFileList = []
				// 材料按文件后缀名分流
				temp.forEach(v => {
					if (this.fileNotImg(v)) {
						otherFileList.push(this.imgSrc + v)
					} else {
						imgFileList.push(this.imgSrc + v)
					}
				})
				this.form._imgFileList = imgFileList
				this.form._otherFileList = otherFileList
				this.form._showImgList = imgFileList.length > 0
				this.form._showOtherFileList = otherFileList.length > 0
			},
			handleDetailRace(row) {
				getArtRaceDetail({
					matchId: row.id
				}).then((res) => {
					this.title = "艺术展演/比赛";
					this.open = true;
					this.form = res.data;
					if (this.form.proofFile) {
						this.processAth(this.form.proofFile)
					}
				});
			},
			handleRemoveRace(row) {
				this.$confirm(
						"删除该条记录后，学生对应的综素得分将被扣除，确定要删除吗？",
						"提示", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "warning",
						}
					)
					.then(() => {
						removeArtRace({
							matchId: row.id
						}).then((res) => {
							this.$message.success("删除成功");
							this.getAll()
						});
					})
					.catch(() => {
						return false;
					});
			},
			getTermList() {
				getTerm({}).then((res) => {
					this.termlist = res.data;
				});
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/studentResult.scss";
</style>
<style scoped lang="scss">
	#total-table1, #total-table2 {
		th {
		  width: 14.285%;
		}
	}
	#total-table1 {
		margin-bottom: 15px;
	}
	.table-box {
		table {

			th,
			td {
				width: 11% !important;
			}

			.long-td {
				width: 17% !important;
			}
			.empty-cell {
				color: #909399 !important;
			}
		}
	}

	.user-nav .el-select>>>.el-input {
		margin: 10px 12px;
	}

	.el-form-item {
		margin-bottom: 12px;
	}

	.el-form-item>>>.el-form-item__label {
		font-weight: bold;
		color: #303133;
	}

	.el-image {
		width: 100%;
	}
</style>